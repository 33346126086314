import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ProductListTile from "../components/ProductListTile";

const productPage = ({ data }) => {

  return (
    <Layout>
      <div className="section">
         <div className="container">

         <h1 className="is-size-1">{data.markdownRemark.frontmatter.title}</h1>
         <br />
              {data.markdownRemark.frontmatter.products.map(p => (
                <div>
                  <ProductListTile item={p} />
                  <br /><br />
                </div>
              ))}
        </div> 
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        tags
        products {
          img
          img2
          img3
          prices {
            priceLabel
            priceAmt
          }
          name
          description
        }
      }
    }
  }
`;

export default productPage;