import React from "react";
import ImageZoom from "../../components/ImageZoom";
import mediumZoom from 'medium-zoom';

const buildPricesFooter = prices => {

  return prices.map(price => (
    <div className="card-footer-item">
      <div className="columns is-multiline">
        <div className="column is-12 has-text-centered">
          <div className="has-text-weight-semibold is-size-7-mobile">{price.priceLabel}</div>
        </div>
        <div className="column is-12 has-text-centered is-size-7-mobile">{price.priceAmt}</div>
      </div>
    </div>
  ));
};

const ProductListTile = ({ item }) => {

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title is-size-4">{item.name}</p>
      </header>

      <div className="card-content">


        {item.img == 'none' ? null :

          <div className="card-image is-flex is-horizontal-center">

            <div className="is-hidden-touch">  
              <figure className={item.img2 ? "image is-512x512 more-padding-left" : "image is-512x512"}>
                
                {(typeof window !== `undefined`) ? 
                <ImageZoom
                  src={item.img ? item.img : "/img/coming-soon.png"}
                  alt={item.name}
                  zoom={React.useRef(mediumZoom()).current}
                  background="#000"
                /> : null }
              

              </figure>
              {item.img2 ? <figure className="image is-512x512 more-padding-left">
              {(typeof window !== `undefined`) ? 
                <ImageZoom
                  src={item.img2}
                  alt={item.name}
                  zoom={React.useRef(mediumZoom()).current}
                  background="#000"
                />: null }</figure> 
                : null}

              {item.img3 ? <figure className="image is-512x512 more-padding-left">
              {(typeof window !== `undefined`) ? 
                <ImageZoom
                  src={item.img3}
                  alt={item.name}
                  zoom={React.useRef(mediumZoom()).current}
                  background="#000"
                />: null }
              </figure> : null}
            </div>

            <div className="is-hidden-desktop">
              <figure className="image is-256x256">
              {(typeof window !== `undefined`) ? 
                <ImageZoom
                  src={item.img ? item.img : "/img/coming-soon.png"}
                  alt={item.name}
                  zoom={React.useRef(mediumZoom()).current}
                  background="#000"
                />: null }
              </figure>
            </div>

          </div>
        }

        <br />
        <div className="content">
          {item.description ?
            <article className="message is-primary">
              <div className="message-body is-size-7-mobile">
                {item.description}
              </div>
            </article>
            : null}
        </div>
        <div className="card-footer">{buildPricesFooter(item.prices)}</div>
      </div>
    </div>
  );
};

export default ProductListTile;
