import React from 'react';

function ImageZoom({ zoom, src, alt, background }) {

    if (typeof window !== `undefined`) {

        const zoomRef = React.useRef(zoom.clone({ background }))

        function attachZoom(image) {
            zoomRef.current.attach(image)
        }

        return <img src={src} alt={alt} ref={attachZoom} />
    }
    else {
        return <img src={src} alt={alt} />
    }
}

export default ImageZoom
